import {Injectable} from '@angular/core';
import {ApiRequest} from './../../request';
import {InGet, OutGet, List} from './interface';
import {Response} from '@core/service/api/request';
import {ApiUtilsService} from '@core/service/api/utils';

@Injectable()
export class CityService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

	/**
	 * Города
	 *
	 * @param {InGet} obj
	 * @return {OutGet}
	 */
	get(obj?: InGet): Promise<OutGet> {
		return this._request
			.query('ma/site/v1/cities', obj)
			.then((res: Response) => res.json);
	}

	/**
	 * Список всех городов
	 *
	 * @return {Promise<Interface['Cities']>}
	 */
	getAll(): Promise<OutGet> {
		const count: number = 499;
		const page: number = 1;
		const fields: string[] = ['id', 'name', 'code'];
		return this.get({count: count, page: page, fields: fields})
			.then((res) => {
				let list: List[] = res.list;

				const arrPromise = [];
				if (res.total > count) {
					const pageCount = Math.ceil(res.total / count);
					for (let i = page + 1; i <= pageCount; i++) {
						arrPromise.push(this.get({count: count, page: i, fields: fields}));
					}
				}

				return Promise.all(arrPromise)
					.then((cities: any): any => {
						cities.forEach((v: OutGet) => {
							list = <List[]>list.concat(v.list);
						});

						return <OutGet>{
							list: list,
							total: res.total
						};
					});
			});
	}
}

