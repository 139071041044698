import {Injectable} from '@angular/core';
import {ApiRequest} from './../../../request';
import {InGet, OutGet} from './interface';
import {Response} from '@core/service/api/request';
import {ApiUtilsService} from '@core/service/api/utils';

@Injectable()
export class OrdersCheckService {

	constructor(protected _request: ApiRequest,
				private _utils: ApiUtilsService) {
	}

	/**
	 * 	 *
	 * @param _obj
	 */
	get(_obj?: InGet): Promise<OutGet> {
		return this._request
			.query('ma/site/v4/orders/check', this._utils.setCity(_obj))
			.then((res: Response) => res.json);
	}
}

