import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectFindComponent} from './select-find/select-find.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';


@NgModule({
	declarations: [SelectFindComponent],
	imports: [
		CommonModule,
		FormsModule,
		NgSelectModule
	],
	exports: [SelectFindComponent]
})
export class InputModule {
}
