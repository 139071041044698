<ng-select
        #select_find
        bindLabel="name"
		bindValue="id"
		notFoundText="{{notFoundText}}"
		[placeholder]="placeholder"
		[isOpen]="isOpen"
		[(ngModel)]="selected"
		[searchable]="search"
		[items]="options"
		[clearSearchOnAdd]="false"
		[clearable]="false"
		[clearOnBackspace]="false"
		[virtualScroll]="true"
		[loading]="loading"
        (change)="onChange($event)">
	<ng-template ng-option-tmp let-item="item" let-index="index">
		{{item.name}}
	</ng-template>
</ng-select>

