import {Injectable} from '@angular/core';
import {ApiRequest} from './../../../request';
import {InGet, OutGet} from './interface';
import {Response} from '@core/service/api/request';
import {ApiUtilsService} from '@core/service/api/utils';

@Injectable()
export class CityLocateService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

	/**
	 * Определение города
	 *
	 * @param {InGet} obj
	 * @return {Promise<OutGet>}
	 */
	get(obj?: InGet): Promise<OutGet> {
		return this._request
			.query('ma/site/v1/cities/locate', obj)
			.then((res: Response) => res.json);
	}
}

