import {Injectable} from '@angular/core';
import {ApiService} from '@core/service/api';
import {InGet, OutGet} from '@core/service/api/methods/items/interface';
import {Config} from '@core/service/config';
import {TokenService} from '@core/service/token';
import {ParametersService} from "@core/service/parameters";

@Injectable({
	providedIn: 'root'
})
export class MainService {

	constructor(private _api: ApiService,
				private _tokenService: TokenService,
				private _parametersService: ParametersService,
				private _config: Config) {
	}

	getItems(search?: string, page?: number): Promise<OutGet> {

		let obj: InGet = {
			fields: ['id', 'code', 'name', 'brand_name', 'images', 'price', 'stores_quantity'],
			id: {
				$in: this._parametersService.parametersData.items_ids
			},
			count: this._parametersService.parametersData.items_count
		};

		if (this._parametersService.parametersData.has_search) {
			if (search) {
				obj = {
					fields: ['id', 'code', 'name', 'brand_name', 'images', 'price', 'stores_quantity'],
					total: true,
					query: null,
					page: page,
					price_from: null,
					price_to: null,
				};

				obj.price_from = this._parametersService.parametersData.items_min_price;
				obj.query = search;
			}
		}
		return this._api.items.get(obj);
	}
}
