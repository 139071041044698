import {Injectable} from '@angular/core';
import {Config} from '@core/service/config';
import {LazyScript} from '@core/service/lazy-script';
import {Platform} from '@core/service/platform';


@Injectable()
export class YandexMapsService {

    constructor(
        private _lazyScript: LazyScript,
        private _platform: Platform,
        private _config: Config) {
    }

    /**
     * Возращает ymaps
     *
     * return ymaps Promise<ymaps>
     */
    public getYmaps(): Promise<Object> {

        const key = this._config.get('ya')['id_maps'];

        if (this._platform.browser) {
            return this._lazyScript
                .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + key, true, false)
                .then(() => {
                    return new Promise((resolve) => {
                        ymaps
                            .ready(() => {
                                resolve(ymaps);
                            });
                    });

                });
        }

        return Promise.resolve({});
    }


}
