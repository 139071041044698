import {Injectable} from '@angular/core';
import {ApiRequest} from './../../request';
import {InGet, OutGet} from './interface';
import {Response} from '@core/service/api/request';
import {ApiUtilsService} from '@core/service/api/utils';

@Injectable()
export class ItemsService {
    constructor(private _request: ApiRequest, private _utils: ApiUtilsService) {
    }

    /**
     *
     * @param {InGet} obj
     * @returns {Promise<OutGet>}
     */
    get(obj?: InGet): Promise<OutGet> {
        if (!obj.total) {
            obj.total = false;
        }

        if (!obj.fields) {
            obj.fields = [
                'id',
                'is_main',
                'name',
                'code',
                'images',
                'brand_name',
                'is_available',
                'group_code',
                'discount_percent',
                'is_cashback',
                'stores_quantity',
                'availables',
                'price',
                'atc',
                'rel_items',
                'is_delivery',
                'is_norecipe'
            ];
        }
        return this._request
            .query('ma/site/v3/items', this._utils.setCity(obj))
            .then((res: Response) => res.json);
    }

}

