import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumEndingPipe} from '@shared/pipe/num-ending/num-ending';
import {FormatPricePipe} from '@shared/pipe/format-price/format-price';
import {NumEndingService} from '@shared/pipe/num-ending/num-ending.service';
import {DateSitePipe} from '@shared/pipe/date-site/date-site';

@NgModule({
	imports: [CommonModule],
	declarations: [NumEndingPipe, FormatPricePipe, DateSitePipe],
	exports: [NumEndingPipe, FormatPricePipe, DateSitePipe],
	providers: [NumEndingService]
})
export class SharedPipeModule {
}
