import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingBigComponent} from './loading-big/loading-big.component';


@NgModule({
	declarations: [
		LoadingBigComponent
	],
	exports: [
		LoadingBigComponent
	],
	imports: [
		CommonModule
	]
})
export class LoadingModule {
}
