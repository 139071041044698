import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './page/main/main.component';


const routes: Routes = [
	{
		path: '',
		component: MainComponent
	},
	{
		path: 'order',
		loadChildren: () => import('./page/order/module').then(m => m.PageBasketOrderModule),
	},
	{
		path: 'success',
		loadChildren: () => import('./page/success/module').then(m => m.PageBasketSuccessModule),
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
