import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SelectFind} from './select-find';
import {Subscription} from 'rxjs';
import {SelectFindService} from '@shared/module/input/select-find/select-find.service';
import {LoadingBigService} from '@shared/module/loading/loading-big/loading-big.service';

@Component({
	selector: 'app-input-select-find',
	templateUrl: 'select-find.component.html',
	styleUrls: ['select-find.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class SelectFindComponent implements OnInit, OnDestroy {
	@Input() control: FormControl;
	@Input() id: string = '';
	@Input() placeholder: string = '';
	@Input() focus: boolean = false;
	@Input() options: SelectFind[];
	@Input() loading: boolean = false;
	@Input() search: boolean = true;
	@Input() isOpen: boolean = undefined;
	@Input() notFoundText: string = 'Не найдено';

	@Output() enter = new EventEmitter<boolean>();
	@Output() change = new EventEmitter<any>();

	@ViewChild('select_find') elemInput: ElementRef;

	public selected;
	private control$: Subscription;

	constructor(private _selectFindService: SelectFindService,
				private _loadingBigService: LoadingBigService) {
	}

	ngOnInit() {
		if (this.focus) {
			this.elemInput.nativeElement.focus();
		}
		if (this.control.value) {
			this.selected = this.control.value;
		}

		this.control$ = this.control.valueChanges.subscribe((res) => {
			this.selected = res;
		});

		this._selectFindService.open.subscribe((res) => {
			this.elemInput['open']();
		});
	}

	ngOnDestroy() {
		if (this.control$) {
			this.control$.unsubscribe();
		}
	}

	onChange(event) {
		this.control.setValue(event['id']);
		setTimeout(() => {
			this._loadingBigService.status.next(false);
		}, 100);
	}
}
