import {Injectable} from '@angular/core';
import {ItemsService} from '@core/service/api/methods/items';
import {OrdersService} from '@core/service/api/methods/orders';
import {CityLocateService} from '@core/service/api/methods/city/locate';
import {CityService} from '@core/service/api/methods/city';
import {OrdersBasketService} from '@core/service/api/methods/orders/basket';
import {OrdersCheckService} from '@core/service/api/methods/orders/check';
import {CartCheckService} from '@core/service/api/methods/cart/check';
import {ParametersService} from "@core/service/api/methods/parameters";


@Injectable()
export class ApiService {

	constructor(
		public items: ItemsService,
		public orders: OrdersService,
		public cityLocate: CityLocateService,
		public city: CityService,
		public ordersBasket: OrdersBasketService,
		public ordersCheck: OrdersCheckService,
		public cartCheck: CartCheckService,
		public parameters: ParametersService,
		) {
	}
}
