import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CityComponent} from './city/city.component';
import {HeaderComponent} from './header.component';
import {InputModule} from '@shared/module/input/input.module';
import {SelectFindService} from '@shared/module/input/select-find/select-find.service';


@NgModule({
	declarations: [
		CityComponent,
		HeaderComponent
	],
	imports: [
		CommonModule,
		InputModule
	],
	exports: [
		HeaderComponent
	]
})
export class HeaderModule {
}
