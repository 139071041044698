<div class="ma-column">
	<app-search *ngIf="mode === 'search'" (searchEvent)="update($event)"></app-search>
	<ng-container *ngIf="!loading">
		<ng-container *ngIf="items.length > 0">
			<div class="ma-list" [class.loading]="loading_lite">
				<perfect-scrollbar class="scroll-container" [config]="config" [scrollIndicators]="true" (psYReachEnd)="isEnd()">
					<div class="ma-list-inn">
						<ng-container *ngFor="let item of items">
							<app-cart [item]="item"></app-cart>
						</ng-container>
					</div>
				</perfect-scrollbar>
			</div>
		</ng-container>

		<ng-container *ngIf="afterSearch === false && mode === 'search'">
			<div class="ma-column__not-items arrow">Введите название лекарства<br />или действующего вещества</div>
		</ng-container>
		<ng-container *ngIf="items.length === 0 && (mode !== 'search' || afterSearch === true)">
			<div class="ma-column__not-items">К сожалению, в вашем городе товары отсутствуют</div>
		</ng-container>
	</ng-container>
</div>
