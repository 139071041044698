import {Component, Input, OnInit} from '@angular/core';
import {Item} from '@core/service/api/methods/items/interface';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {
	@Input() item: Item;
	public img: string = '';
	constructor() {
	}

	ngOnInit(): void {
		if (this.item) {
			if (this.item.images) {
				if (this.item.images.length > 0) {
					if (this.item.images[0]) {
						this.img = encodeURI(this.item.images[0]);
					}
				}
			}
		}
	}

}
