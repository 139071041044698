import {Injectable} from '@angular/core';
import {Config} from '../config';

@Injectable()
export class Log {
    private isDebug: boolean = false;
    private _config: Config = new Config();

    constructor() {
        this.isDebug = <boolean>this._config.get('debug');
    }

    /**
     * Выводит в консоль данные error
     *
     * @param title названия
     * @param data данные
     */
    public error(title: string, data: any): void {
        if (this.isDebug) {
            console.error(data);
        }

    }

    /**
     * Выводит в консоль данные warn
     *
     * @param title названия
     * @param data данные
     */
    public warn(title: string, data: any): void {
        if (this.isDebug) {
            console.warn(title + ': ' + this.prepare(data));
        }
    }

    /**
     * Выводит в консоль данные info
     *
     * @param title названия
     * @param data данные
     */
    public info(title: string, data: any): void {
        if (this.isDebug) {
            // tslint:disable
            console.info(title + ': ' + this.prepare(data));
        }
    }

    /**
     * Выводит в консоль данные debug
     *
     * @param title названия
     * @param data данные
     */
    public debug(title: string, data: any): void {
        if (this.isDebug) {
            console.debug(title + ': ' + this.prepare(data));
        }
    }

    /**
     * Выводит в консоль данные log
     *
     * @param title названия
     * @param data данные
     */
    public log(title: string, data: any): void {
        if (this.isDebug) {
            console.log(title + ': ' + this.prepare(data));
        }
    }

    /**
     * Возращает подготовленую строку для консоли
     *
     * @param data
     * @returns {string}
     */
    private prepare(data: any): string {
        let str: string;
        if (typeof data === 'object') {
            str = JSON.stringify(data);
        } else {
            str = <string>data;
        }
        return str;
    }
}
