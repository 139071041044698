import {Injectable} from '@angular/core';
import {Platform} from '../platform';

@Injectable()
export class LsService {
    private ls;

    constructor(private _platform: Platform) {
        if (this._platform.browser) {
            this.ls = localStorage;
        }
    }

    /**
     * Возращает данные по ключу
     *
     * @returns {any[]}
     */
    public get(name: string): string {
        if (this._platform.browser) {
            return this.ls.getItem(name);
        } else {
            return '';
        }
    }

    /**
     * Возращает все данные
     *
     * @returns {any[]}
     */
    public getList(): any[] {
        const res: any[] = [];

        for (let i = 0, len = this.ls.length; i < len; i++) {
            const key = this.ls.key(i);
            const value = this.ls[key];

            res.push(value);
        }

        return res;
    }

    /**
     * Запись
     *
     * @param key
     * @param value
     */
    public set(key: string, value: any): void {
        if (this._platform.browser) {
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            this.ls.setItem(key, value);
        }
    }

    /**
     * Обработка данных
     *
     * @param val
     * @returns {any}
     */
    private prepare(val: any): any {

        if (typeof val === 'object') {

            try {
                return JSON.parse(val);
            } catch (e) {
                return '';
            }
        }

        return val;
    }

    /**
     * Удаления
     *
     * @param {string} key
     */
    public remove(key: string) {
        this.ls.removeItem(key);
    }
}
