import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
	public isFocus: boolean = false;
	public search;
	@Output() searchEvent = new EventEmitter<string>();
	timeout: any = null;

	constructor() {
	}

	ngOnInit(): void {
	}

	onSearch(event: any) {
		if(this.search) {
			if (this.search.length > 0) {
				clearTimeout(this.timeout);
				const $this = this;
				if (event.keyCode === 13) {
					$this.searchEvent.emit($this.search);
				} else {
					this.timeout = setTimeout(function () {
						$this.searchEvent.emit($this.search);
					}, 500);
				}
			}
		}
	}
}
