import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SelectFindService {
	public open: Subject<boolean> = new Subject<boolean>();

	constructor() {
	}
}
