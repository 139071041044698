import { Injectable } from '@angular/core';
import { LsService } from '../localstorage';

@Injectable()
export class Storage {
	private nameCity = 'ma_city';

	constructor(protected _localStorage: LsService) {}

	/**
	 * Значение города
	 *
	 * @returns {string}
	 */
	protected get city(): string {
		return this.get(this.nameCity);
	}

	/**
	 * Запись города
	 *
	 * @returns {string}
	 */
	protected set city(code: string) {
		this.set(this.nameCity, code);
	}

	/**
	 * Запись значение в хранилище
	 *
	 * @param {string} name
	 * @param {string} value
	 */
	private set(name: string, value: string): void {
		this._localStorage.set(name, value);
	}

	/**
	 * Возращает значение из хранилище
	 *
	 * @param {string} name
	 * @returns {string}
	 */
	private get(name: string): string {
		return this._localStorage.get(name);
	}
}
