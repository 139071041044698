import {Injectable} from '@angular/core';
import {LsService} from '../localstorage';
import {Basket, Items} from './interface';
import {forEach, find} from 'lodash-es';
import {CityService} from '../city';
import {Subject} from 'rxjs';
import {Platform} from '../platform';
import {ActivatedRoute, Router} from '@angular/router';
import {filter as Rxfilter} from 'rxjs/operators';
import {ApiService} from '@core/service/api';

export * from './interface';

@Injectable()
export class BasketService {
    private _count: Subject<number> = new Subject();
    public sum$: Subject<number> = new Subject();
    private name = 'basket';
    public count = 0;
    private add: Subject<{ id: number; count: number }> = new Subject();
    public sum = 0;

    constructor(private _ls: LsService,
                private _api: ApiService,
                private _city: CityService,
                private _platform: Platform,
                private _activatedRoute: ActivatedRoute,
                private _router: Router) {

        this.countItemsAll();

        this._city.city$.subscribe(() => {
            this.sumItems();
        });
    }

    /**
     * Обновление корзины
     *
     */
    event() {
        return this._count;
    }

    /**
     * Добавление в корзину
     *
     * @returns {Subject<{id: number; count: number}>}
     */
    eventAdd() {
        return this.add;
    }

    /**
     * Вся корзина
     *
     * @returns {Basket[]}
     */
    public get() {
        if (this._platform.browser) {
            return <Basket[]>this.prepareOf(this._ls.get(this.name));
        }

        return [];
    }

    /**
     *  Количества всех товаров в корзине
     */
    public countItemsAll() {
        const obj = this.get();
        this.count = <number>obj.reduce((a: number, c: { id: number; count: number }) => {
                a += c.count;
                return a;
        }, 0);

        this._count.next(this.count);
    }

    /**
     *  Количества товара в корзине
     */
    public countItems(id: number): number {
        const tmp = this.getItem(id);
        if (!tmp) {
            return 0;
        }
        return tmp.count;
    }

    /**
     * Сумма товаров
     */
    public sumItems() {
        const obj = this.get();
        const ids: number[] = [];
        forEach(obj, function (v) {
            ids.push(v.id);
        });
        return this
            .getItemsApi(ids)
            .then(function (res: any) {
                let sum = 0;
                forEach(res, function (v: any) {
                    const item: any = find(obj, {id: v.id});
                    if (v.price_discount) {
                        sum += v.price_discount * item.count;
                    } else {
                        sum += v.price * item.count;
                    }
                });

                return sum;
            })
            .then((res: any) => this.sum = res.toFixed(2))
            .then((res) => {
                this.sum$.next(res);
            });
    }

    private getItemsApi(items: number[] | null) {
        const params: any = {
            id: {$in: items},
            city_id: this._city.data.id
        };
        return new Promise((resolve, reject) => {
            if (items.length > 0) {
                this
                    ._api
                    .items.get(params)
                    .then(function (res: any) {
                        resolve(<Items[]>res.list);
                    });
            } else {

                resolve(<Items[]>[]);
            }
        });
    }

    /**
     * Проверка товара в корзине
     *
     * @param {number} _item
     * @return {boolean}
     */
    public isItem(_item: number): boolean {
        const item = this.getItem(_item);
        return (item !== null);
    }

    /**
     * Товар из корзины
     *
     * @param {number} item
     * @returns {Basket}
     */
    public getItem(item: number): Basket | null {
        let res: Basket = null;
        if (this._platform.browser) {
            const list = this.prepareOf(this._ls.get(this.name));
            const obj = find(list, ['id', item]);

            if (typeof obj !== 'undefined') {
                res = {
                    id: obj['id'],
                    count: obj['count']
                };
            }
        }
        return res;
    }

    /**
     * Перезапись всей корзины
     *
     * @param {Basket[]} obj
     */
    public set(obj: Basket[]) {
        this._ls.set(this.name, this.prepareIn(obj));
        this.countItemsAll();
        this.sumItems();
    }

    /**
     * Добавления товара в корзину
     *
     * @param {number} _item
     * @param {number} _count
     */
    public upsertItem(_item: number, _count: number) {
        const tmp = {
            id: _item,
            count: _count
        };
        let type = '';
        const item = this.getItem(_item);
        const list = this.get();
        if (item !== null) {
            forEach(list, function (v: any, k: any) {
                if (v.id === _item) {
                    list[k].count = _count;
                }
            });
            type = 'update';
        } else {
            list.push({
                id: _item,
                count: _count
            });
            type = 'add';
        }

        this.set(list);

        if (type === 'add') {
            this.add.next(tmp);
        }
    }

    /**
     * Добавления количества в корзину
     *
     * @param {number} _item
     * @param {number} _count
     */
    public plusCount(_item: number, _count: number = 1) {
        const obj = this.getItem(_item);
        let count: number;

        if (obj !== null) {
            count = obj.count + _count;
        } else {
            count = _count;
        }

        this.upsertItem(_item, count);
    }

    /**
     * Добавления количества в корзину
     *
     * @param {number} _item
     * @param {number} _count
     */
    public minusCount(_item: number, _count: number = 1) {
        const obj = this.getItem(_item);
        let count: number;
        if (obj !== null) {
            if (obj.count > _count) {
                count = obj.count - _count;
                this.upsertItem(_item, count);
            } else if (obj.count <= _count) {
                this.deleteItem(_item);
            }
        }
    }

    /**
     * Очистка корзины
     */
    public clear() {
        this.set(<Basket[]>[]);
    }

    /**
     *   /**
     * Удаления товара
     *
     * @param {number} _item
     *
     * @returns {any}
     */
    public deleteItem(_item: number) {
        const list = this.get();
        const listNew: any = [];

        forEach(list, (v, k) => {
            if (v.id !== _item) {
                listNew.push(v);
            }
        });

        this.set(<Basket[]>listNew);
    }

    /**
     * Обработка данных из store
     *
     * @param {string} list
     * @returns {Basket[]}
     */
    private prepareOf(list?: string) {
        if (!list) {
            list = '[]';
        }

        let obj = [];
        try {
            obj = JSON.parse(list);

            const listNew: any = [];
            forEach(obj, function (v: any, k: any) {
                listNew.push({
                    id: v.i,
                    count: v.c
                });
            });

            return listNew;
        } catch (e) {
            return [];
        }
    }

    /**
     * Обработка данных в store
     *
     * @param {Basket[]} list
     * @return {string}
     */
    private prepareIn(list?: Basket[]): string {
        if (list) {
            const listNew: any = [];
            forEach(list, function (v, k) {
                listNew.push({
                    i: v.id,
                    c: v.count
                });
            });

            return JSON.stringify(listNew);
        }

        return '';
    }

    // /**
    //  * Добавление товаров через урл
    //  */
    // public addAcrossUrl() {
    //     this._activatedRoute.queryParams
    //         .pipe(Rxfilter((params) => {
    //             return params['basket_add'];
    //         }))
    //         .subscribe((params) => {
    //             this._api.ordersBasket
    //                 .get({token: params['basket_add']})
    //                 .then((res) => {
    //                     forEach(res.items, (v) => {
    //                         this.upsertItem(v.id, v.quantity);
    //                     });
    //                     this._router.navigate(
    //                         ['.'],
    //                         {relativeTo: this._activatedRoute, queryParams: {basket_add: null}}
    //                     );
    //                 });
	//
    //         });
    // }
}
