import {PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MainService} from './main.service';
import {Item, OutGet} from '@core/service/api/methods/items/interface';
import {CityService} from '@core/service/city';
import {skip} from 'rxjs/operators';
import {LoadingBigService} from '@shared/module/loading/loading-big/loading-big.service';
import {TokenService} from '@core/service/token';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
	public config: PerfectScrollbarConfigInterface = {wheelPropagation: false, suppressScrollX: true};
	@ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
	@ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
	public items: Item[] = [];
	public loading: boolean = true;
	public loading_lite: boolean = false;
	public page: number = 1;
	public total: number = 0;
	public search: string = '';
	public mode: any = '';
	public afterSearch: boolean = false;

	constructor(private _service: MainService,
				private _city: CityService,
				private _loading: LoadingBigService,
				private _cdr: ChangeDetectorRef,
				private _tokenService: TokenService) {
	}

	ngOnInit() {
		this.mode = this._tokenService.mode;
		this._city.city$
			.subscribe((res) => {
				this.update();
			});
	}

	isEnd() {
		if (this.mode === 'search') {
			++this.page;
			if (this.items.length < this.total) {
				this.loading_lite = true;
				this._cdr.detectChanges();
				this._service
					.getItems(this.search, this.page)
					.then((res) => {
						const tpm: any = this.items.concat(res.list);
						this.items = tpm;
						if (res.total) {
							this.total = res.total;
						}
						this.componentRef.directiveRef.update();
						this._cdr.detectChanges();
					}).then(() => {
					setTimeout(() => {
						this.loading_lite = false;
						this._cdr.detectChanges();
					}, 100);
				});
			}
		}
	}

	update(search?: string) {
		this.page = 1;
		this.search = search;
		this.loading = true;
		this.loading_lite = true;
		this._cdr.detectChanges();
		this._service
			.getItems(search, this.page)
			.then((res) => {
				this.items = res.list;
				if (res.total) {
					this.total = res.total;
				}
			}).then(() => {
				this._loading.status.next(false);
				this.loading = false;
				this.loading_lite = false;
				this._cdr.detectChanges();
			});
		if (search) {
			if (search.length > 0) {
				this.afterSearch = true;
			}
		}
	}
}
