import {Injectable} from '@angular/core';
import {ApiRequest} from './../../request';
import {InPost, OutPost} from './interface';
import {ActivatedRoute} from '@angular/router';
import {Config} from '@core/service/config';
import {Response} from '@core/service/api/request';

@Injectable()
export class OrdersService {

    constructor(protected _request: ApiRequest,
                protected _activatedRoute: ActivatedRoute,
                protected _config: Config) {
    }

    /**
     * Создание заказа
     *
     * @param _obj
     */
    post(_obj?: InPost): Promise<OutPost> {
        return this._request
            .create('ma/site/v5/orders', _obj)
            .then((res: Response) => res.json);
    }
}

