import {Inject, Injectable, Injector} from '@angular/core';
import {CityService} from '@core/service/city/index';
import {Url} from '@core/service/url';
import {Router} from '@angular/router';
import {forEach} from 'lodash-es';
import {ApiService} from '@core/service/api';
import {RedirectService} from '@core/service/redirect';


@Injectable()
export class LoadUtilsService {
	constructor(
		@Inject('ORIGIN_HREF') public baseHref: string,
		@Inject(ApiService) public _api: ApiService,
		@Inject(Injector) public _injector: Injector,
		@Inject(CityService) public _cityService: CityService,
		@Inject(RedirectService) public _redirectService: RedirectService,
		@Inject(Url) public _url: Url) {
	}

	protected getCity(): Promise<any> {

		const cityId = this._cityService.getCity();
		const obj = {};

		return new Promise((resolve, reject) => {
			if (cityId) {
				obj['id'] = cityId;

				this._api
					.city
					.get(obj)
					.then((res) => {
						resolve(res.list[0]);
					}).catch((e) => {
					reject(e);
				});
			} else {
				resolve({});
			}

		});
	}

	/**
	 * Определяем есть в адресной строке город
	 *
	 * @returns {string}
	 */
	getCityNameUrl(): string {
		const arrPath: string[] = [];
		const router = this._injector.get(Router);
		forEach(router.config, (v) => {
			arrPath.push(v.path);
		});

		const objUrl = this._url.parser(this.baseHref);
		if (objUrl.pathname.length > 1) {
			const arr = objUrl.pathname.split('/').splice(1);
			if (arr.length >= 1) {
				if (arrPath.indexOf(arr[0]) === -1) {
					return arr[0];
				}
			}
		}

		return '';
	}


	redirectUndefined(city: any) {
		if (city === 'undefined') {
			const cityNew = 'moskva';
			const objUrl = this._url.parser(this.baseHref);
			const url = objUrl.pathname;
			const urlNew = url.replace(/undefined/ig, cityNew);
			this._redirectService._301(urlNew);
		}
	}
}
