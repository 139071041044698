import {Injectable} from '@angular/core';
import {ApiRequest} from './../../../request';
import {InGet, OutGet} from './interface';
import {Response} from '@core/service/api/request';
import {ApiUtilsService} from '@core/service/api/utils';

@Injectable()
export class CartCheckService {

    constructor(private _request: ApiRequest, private _apiUtilsService: ApiUtilsService) {
    }

    /**
     *
     * @return {Promise<OutGet>}
     */
    get(obj: InGet): Promise<boolean> {
        return this._request
            .query('ma/site/v1/cart/check', this._apiUtilsService.setCity(obj))
            .then((res: Response) => res.json);
    }

}




