import {Inject, Injectable} from '@angular/core';
import {Location} from '@core/service/url/interface';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class Url {

    constructor(@Inject(DOCUMENT) public _document: Document) {

    }

    /**
     * Возращает Location
     *
     * @param {string} _url
     * @returns {Location}
     */
    parser(_url: string): Location {
        const link = this._document.createElement('a');
        link.href = _url;
        return {
            protocol: link.protocol,
            host: link.host,
            hostname: link.hostname,
            port: link.port,
            pathname: link.pathname,
            search: link.search,
            hash: link.hash
        };
    }
}
