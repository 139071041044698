import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TokenService {
	public token: string = '';
	public mode: string = '';
    constructor() {

    }

    set(token: string) {
    	this.token = token;
	}
	setMode(mode: string) {
    	this.mode = mode;
	}
}
