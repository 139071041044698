import {Injectable} from '@angular/core';
import {ApiService} from "@core/service/api";

@Injectable()
export class ParametersService {
	public parametersData: {
		has_search: boolean;
		items_count: number;
		items_ids: number[];
		items_min_price: number;
	} = {has_search:false, items_count: 0, items_ids: [], items_min_price: 0};

	constructor(private _api: ApiService) {
	}

	/**
	 * Список партнеров
	 *
	 * @param {InBanners} obj
	 * @returns {Promise<Banners>}
	 */

	load() {
		const url = new URL(window.location.href);
		const id = url.searchParams.get('id');
		return this._api.parameters.get({partner_code: id})
			.then(((res) => {
				res.list.forEach((value) => {
					if(value.key === 'has_search'){
						this.parametersData[value.key] = value.value === 'true';
					}
					if(value.key === 'items_count' || value.key === 'items_min_price'){
						this.parametersData[value.key] = +value.value;
					}
					if(value.key === 'items_ids'){
						this.parametersData[value.key] = value.value.split(',').map((res) => +res.trim() );
					}
				});
				return;
			}));
	}
}

