import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {SearchComponent} from './search/search.component';
import {FormsModule} from '@angular/forms';
import {CartComponent} from './cart/cart.component';
import {SharedPipeModule} from '@shared/pipe/shared.pipe';
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {RouterModule} from '@angular/router';

@NgModule({
	declarations: [
		MainComponent,
		SearchComponent,
		CartComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SharedPipeModule,
		RouterModule,
		PerfectScrollbarModule
	]
})
export class MainModule {
}
