<div class="ma-search" [class.focus]="isFocus">
	<label>
		<input type="text"
			   minlength="2"
			   (focus)="isFocus = true"
			   (blur)="isFocus = false"
			   [(ngModel)]="search"
			   (keyup)="onSearch($event)"
			   maxlength="400"
			   autocomplete="off"
			   autocapitalize="off"
			   autocorrect="off"
			   spellcheck="false"
			   placeholder="Введите название"
			   name="text"
			   class="masi"
			   value=""/>
	</label>
</div>
