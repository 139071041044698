import {Inject, Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import forEach from 'lodash-es/forEach';
import {CookieService} from 'ngx-cookie-service';
import {HmacSHA256} from 'crypto-js';
import {Config} from '../config';
import {Log} from '../logger';
import {Platform} from '../platform';

export interface Response {
	status: number;
	json: any;
}

@Injectable()
export class ApiRequest {
	protected data: {} = {};
	protected json: string;

	constructor(private _http: HttpClient,
				private _config: Config,
				private _log: Log,
				private _cookieService: CookieService,
				private _platform: Platform) {
	}

	/**
	 *
	 *
	 * @param {string} _path
	 * @param {Object} _data
	 * @param {string[]} _files
	 * @param {boolean} _isServer
	 * @return {Promise<Response>}
	 */
	public query(_path: string, _data: object = {}, _files: string[] = [], _isServer: boolean = true) {
		this.params(_data);

		const json: string = this.json;
		let dataJson: string;

		if (json !== '') {
			dataJson = '?data=' + encodeURIComponent(json);
		}

		const url: string = this.createUrl(_path) + dataJson;
		this._log.debug('GET', url);

		const key = _path + json;

		return this._http
			.get(url, {headers: this.header()})
			.toPromise()
			.then((body: any) => {
				return this.reply(body);
			});
	}

	/**
	 * Обновления
	 *
	 * @param {string} _path
	 * @param {Object} _data
	 * @param {string[]} _files
	 * @return {Promise<Response>}
	 */
	public update(_path: string, _data: object = {}, _files: string[] = []) {
		this.params(_data);

		const url: string = this.createUrl(_path);
		this._log.debug('PUT', url);
		return this._http
			.put(url, this.json, {headers: this.header()})
			.toPromise()
			.then((res: Response) => {
				return this.reply(res);

			}).catch((error: HttpErrorResponse) => {
				throw this.replyError(error);
			});
	}

	/**
	 * Создания
	 *
	 * @param {string} _path
	 * @param {Object} _data
	 * @param {string[]} _files
	 * @return {Promise<Response>}
	 */
	public create(_path: string, _data: object = {}, _files: string[] = []) {
		this.params(_data);

		const url: string = this.createUrl(_path);
		this._log.debug('POST', url);
		return this._http
			.post(url, this.json, {headers: this.header()})
			.toPromise()
			.then((res: Response) => {
				return this.reply(res);
			}).catch((error: HttpErrorResponse) => {
				throw this.replyError(error);
			});
	}

	/**
	 * Возращает отформатированный ответ из запроса
	 *
	 * @param {Response} res
	 * @return {ApiResponse}
	 */
	private reply(res: Response) {
		const reply = {
			json: res
		};
		return <Response>reply;
	}

	/**
	 * Возращает отформатированный ответ ошибки
	 *
	 * @param {HttpErrorResponse} res
	 *
	 * @return {}
	 */
	private replyError(res: HttpErrorResponse) {
		const reply = {
			message: res.error.message,
			status: res.status
		};

		return <{ message: string; status: number }>reply;
	}


	/**
	 * Создания url
	 *
	 * @param path
	 * @returns {string}
	 */
	protected createUrl(path: string): string {
		let urlPath = '';
		const api = this._config.get('api');
		const port = api['port'];
		const protocol = api['protocol'];
		const url = api['url'];

		urlPath += protocol + '://';
		urlPath += url;
		if (port) {
			urlPath += ':' + port;
		}
		urlPath += '/' + path + '/';

		return urlPath;
	}

	/**
	 * Возращает header
	 *
	 * @param params
	 * @returns {Headers}
	 */
	protected header(params: {} = {}): HttpHeaders {
		let headers: HttpHeaders = new HttpHeaders({});
		const configHeader = this._config.get('api')['header'];

		/**
		 * Конфиг
		 */
		forEach(configHeader, function (val, key) {
			headers = headers.append(key.toString(), val.toString());
		});

		/**
		 * Входящие параметры
		 */
		if (params) {
			forEach(params, function (val: any, key: any) {
				headers = headers.set(<string>key, <string>val);
			});
		}

		/**
		 * Токен
		 */
		headers = headers.append('token', this.createToken());

		if (this._platform.browser) {
			const authToken = (this._cookieService.get('auth_token')) ? this._cookieService.get('auth_token') : '';
			if (authToken.length > 0) {
				headers = headers.append('auth_token', authToken);
			}
		}

		return headers;
	}


	/**
	 * Подготовка данных
	 *
	 * @param _data обьект с данными
	 *
	 * @returns {string}
	 */
	protected params(_data: object): void {
		this.data = _data;
		this.json = <string>JSON.stringify(this.data);
	}

	/**
	 * Создания токен
	 */
	protected createToken(): string {

		const api = this._config.get('api');
		const secret = <string>api['secret'];
		const dataJson = JSON.stringify(this.data);

		const token = <string>HmacSHA256(dataJson, secret)
			.toString()
			.toUpperCase();

		this._log.debug('Token data', this.data);
		this._log.debug('Token json', dataJson);
		this._log.debug('Token', token);

		return token;
	}
}
