import {Inject, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {HttpStatus} from '@core/service/http-status';
import {Redirect} from '@core/service/redirect/interface';

@Injectable()
export class RedirectBrowserService implements Redirect {

    constructor(@Inject(Injector) public _injector: Injector,
                @Inject(HttpStatus) public _httpStatus: HttpStatus) {
    }

    _200(url: string, query?: any) {
        const route = this._injector.get(Router);
        route.navigate([url], {queryParams: query});
    }

    _301(url: string, query?: any) {
        const route = this._injector.get(Router);
        route.navigate([url], {queryParams: query});
    }

    _404() {
        const route = this._injector.get(Router);
        route.navigateByUrl('/404');
    }

}
