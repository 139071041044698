import {Injectable} from '@angular/core';
import {ApiRequest} from './request';
import {ActivatedRoute} from '@angular/router';
import {Config} from '@core/service/config';
import {CityService} from '@core/service/city';
import {MobileService} from '@core/service/mobile';

@Injectable()
export class ApiUtilsService {

    constructor(protected _request: ApiRequest,
                protected _city: CityService,
                protected _activatedRoute: ActivatedRoute,
                protected _config: Config,
                private _mobile: MobileService) {
    }


    /**
     * Метод добавляет город к запрасам
     *
     * @param obj
     * @returns {any}
     */
    setCity(obj?: any) {
        if (!obj) {
            obj = {};
        }
        let tmp = JSON.parse(JSON.stringify(obj));
        if (tmp === undefined) {
            tmp = {};
        }
        if (!tmp.city_id && tmp.city_id !== false) {
            tmp['city_id'] = this._city.data.id;
        }
        if (tmp.city_id === false) {
            delete tmp['city_id'];
        }

        return tmp;
    }

    /**
     * Количество по умолчанию (все 500)
     *
     * @param obj
     * @returns {any}
     */
    setCount(obj?: any) {
        let tmp = obj;
        if (tmp === undefined) {
            tmp = {};
        }
        if (!tmp.count) {
            tmp['count'] = 499;
        }

        return tmp;
    }


    /**
     * тип устройства
     * 41 — desktop site, 42 — mobile site
     *
     * @param obj
     * @returns {any}
     */
    setDevice(obj?: any) {
        let deviceId: number = 41;

        if (this._mobile.isMobile()) {
            deviceId = 42;
        }

        let tmp = obj;
        if (tmp === undefined) {
            tmp = {};
        }
        if (!tmp.device_type_id) {
            tmp['device_type_id'] = deviceId;
        }

        return tmp;
    }
}

