import {Injectable} from '@angular/core';
import {Cookie} from '@core/service/cookie';

@Injectable()
export class Storage {
    private name = 'ma_referer';

    constructor(protected _cookie: Cookie) {
    }

    /**
     * Значение
     *
     * @returns {string}
     */
    protected get storage(): string {
        return this._cookie.getItem(this.name);
    }

    /**
     * Запись
     *
     * @returns {string}
     */
    protected set storage(value: string) {
        if (value) {
            this._cookie.setItem(this.name, value);
        } else {
            this._cookie.removeItem(this.name);
        }
    }
}
