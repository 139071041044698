import {Inject, Injectable} from '@angular/core';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

@Injectable()
export class Platform {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    get browser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    get server(): boolean {
        return isPlatformServer(this.platformId);
    }
}
