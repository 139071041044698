import {Pipe, PipeTransform} from '@angular/core';
import {NumEndingService} from '@shared/pipe/num-ending/num-ending.service';

@Pipe({
    name: 'numEnding'
})
export class NumEndingPipe implements PipeTransform {
    constructor(private _text: NumEndingService) {}

    transform(_number: number, _words: string[]) {
        return this._text.transform(_number, _words);
    }
}
