<div class="ma-card">
	<div class="ma-card-name">{{item.name}}</div>
	<div class="ma-card-line">
		<div class="ma-card-img">
			<img *ngIf="img"  src="{{img}}" alt="">
			<div class="ma-card-img__nopic" *ngIf="!img"></div>
		</div>
		<div class="ma-card-info">
			<div class="ma-card-info-brand">{{item.brand_name}}</div>
			<ng-container *ngIf="item.stores_quantity">
				<div class="ma-card-info-apt">
					В {{item.stores_quantity}} {{item.stores_quantity | numEnding:['аптеке', 'аптеках', 'аптеках']}}</div>
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="item.price">
		<div class="ma-card-line">
			<div class="ma-card-price">от <b>{{item.price}}</b> ₽</div>
			<a routerLink="order" [queryParams]="{basket_add: item.id}" class="ma-card-btn">Забронировать</a>
		</div>
	</ng-container>
</div>
