import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@core/module';
import {LoadService} from '@core/service/city/loadBrowser';
import {HeaderModule} from './header/header.module';
import {RedirectInject} from '@core/service/redirect/inject';
import {RedirectBrowserService} from '@core/service/redirect/browser';
import {LoadingModule} from '@shared/module/loading/loading.module';
import {MainModule} from './page/main/main.module';
import {RouterModule} from '@angular/router';
import {ParametersService} from "@core/service/parameters";
import {ApiService} from "@core/service/api";

export function getRequest(): any {
	return {headers: {cookie: document.cookie}};
}
@NgModule({
	declarations: [

		AppComponent
	],
	imports: [

		BrowserModule,
		RouterModule,
		AppRoutingModule,
		CoreModule,
		HeaderModule,
		LoadingModule,
		MainModule
	],
	providers: [
		LoadService,
		{
			// The server provides these in main.server
			provide: REQUEST,
			useFactory: (getRequest)
		},
		{provide: RedirectInject, useClass: RedirectBrowserService},
		{
			provide: APP_INITIALIZER,
			useFactory: (_loadService: LoadService) => () => _loadService.load(),
			deps: [LoadService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (_parametersService: ParametersService) => () => _parametersService.load(),
			deps: [ParametersService, ApiService],
			multi: true
		},
		{
			provide: 'ORIGIN_URL',
			useValue: location.origin
		},
		{
			provide: 'ORIGIN_HEADERS',
			useValue: null
		},
		{
			provide: 'ORIGIN_HREF',
			useValue: (`${location.href}`)
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
