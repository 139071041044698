import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoadingBigService {
	public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor() {
	}
}
