// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	api: {
		url: 'megapteka-api.vigroup.ru',
		port: '',
		protocol: 'https',
		secret: '66DtPBSYVh98NhBpVJEzEAnqju02DbadkVhyP9AzD8VgmrAonbzs2yrOKjUiEDcU',
		ncp: true,
		header: {
			'Content-Type': 'application/json',
			'platform': 4
		}
	},
	site: {
		domain: 'Megapteka.ru',
		host: 'https://megapteka-shop.vigroup.ru',
		title: '',
		date: {
			full: 'DD.MM.YYYY HH:mm',
			time: 'HH:mm',
			date: 'DD.MM.YYYY'
		},
		def: {
			autoCompleteCount: 10,
			perPageList: [5, 15, 30],
			showPageCount: 7
		}
	},
	shop: {
		minSumOrder: 100
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
