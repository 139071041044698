import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingBigService} from '@shared/module/loading/loading-big/loading-big.service';

@Component({
	selector: 'app-loading-big',
	templateUrl: './loading-big.component.html',
	styleUrls: ['./loading-big.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBigComponent implements OnInit {
	public status: boolean = true;
	constructor(private _loadingBigService: LoadingBigService, private _cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this._loadingBigService.status.subscribe((res) => {
			this.status = res;
			this._cdr.markForCheck();
		});
	}

}
