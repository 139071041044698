import {Injectable} from '@angular/core';
import {ApiRequest} from './../../../request';
import {InGet, OutGet} from './interface';
import {ActivatedRoute} from '@angular/router';
import {Config} from '@core/service/config';
import {Response} from '@core/service/api/request';

@Injectable()
export class OrdersBasketService {

    constructor(protected _request: ApiRequest,
                protected _activatedRoute: ActivatedRoute,
                protected _config: Config) {
    }

    /**
     * Отправка кэшбэка
     *
     * @param _obj
     */
    get(_obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v4/orders/basket', _obj)
            .then((res: Response) => res.json);
    }
}

