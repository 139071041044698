import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingBigService} from '@shared/module/loading/loading-big/loading-big.service';
import {Refresh} from '@core/service/refresh';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TokenService} from '@core/service/token';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

	public statusLoading: boolean = true;
	public refresh: boolean = true;

	constructor(private _loadingBigService: LoadingBigService,
				private _refresh: Refresh,
				private _router: Router,
				private _activatedRoute: ActivatedRoute,
				private _tokenService: TokenService,
				private _cdr: ChangeDetectorRef) {
	}

	ngOnInit() {
		this._loadingBigService.status.subscribe((res) => {
			this.statusLoading = res;
			this._cdr.markForCheck();
		});

		// Перезагружает body
		this._refresh.routerOutlet.subscribe((res: boolean) => {
			this.refresh = res;
		});

		this._router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				window.scrollTo(0, 0);
			});

		this._activatedRoute.queryParams.subscribe((res) => {
			if (res['id']) {
				this._tokenService.set(res['id']);
			}
			if (res['mode']) {
				this._tokenService.setMode(res['mode']);
			}
		});
	}
}
