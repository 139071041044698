import {Injectable} from '@angular/core';
import {ApiRequest} from './../../request';
import {Response} from '@core/service/api/request';
import {InGet, OutGet} from "@core/service/api/methods/parameters/interface";

@Injectable()
export class ParametersService {
    constructor(private _request: ApiRequest) {
    }

    /**
     * Список партнеров
     *
     * @param {InBanners} obj
     * @returns {Promise<Banners>}
     */
    get(_obj?: InGet): Promise<OutGet> {
        return this._request
            .query('ma/site/v1/widget/parameters', _obj)
            .then((res: Response) => res.json);
    }
}

