import {Inject, Injectable, Injector} from '@angular/core';
import {CityService} from '@core/service/city/index';
import {ApiService} from '@core/service/api';
import {LoadUtilsService} from '@core/service/city/loadUtils';
import {Url} from '@core/service/url';
import {LsService} from '@core/service/localstorage';
import {RedirectService} from '@core/service/redirect';


@Injectable()
export class LoadService extends LoadUtilsService {
    constructor(
        @Inject('ORIGIN_HREF') public baseHref: string,
        @Inject(Injector) public _injector: Injector,
        @Inject(Url) public _url: Url,
        @Inject(CityService) public _cityService: CityService,
        @Inject(ApiService) public _api: ApiService,
        @Inject(RedirectService) public _redirectService: RedirectService,
        private _ls: LsService) {

        super(baseHref, _api, _injector, _cityService, _redirectService, _url);
    }

    /**
     * Перед запуском запрашиваем данные города
     *
     * @returns {Promise<any[]>}
     */
    public load() {
        return new Promise((resolve, reject) => {
            this.getCity()
                .then((res) => {
                    this._cityService.setCity(res, false);
                    resolve();
                });
        });
    }


}
