/* Module */
import {NgModule} from '@angular/core';


/* Service */
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './api';
import {ApiRequest} from './request';


import {ApiUtilsService} from '@core/service/api/utils';


import {ItemsService} from '@core/service/api/methods/items';

import {OrdersService} from '@core/service/api/methods/orders';
import {CityLocateService} from '@core/service/api/methods/city/locate';
import {CityService} from '@core/service/api/methods/city';
import {OrdersBasketService} from '@core/service/api/methods/orders/basket';
import {OrdersCheckService} from '@core/service/api/methods/orders/check';
import {CartCheckService} from '@core/service/api/methods/cart/check';
import {ParametersService} from "@core/service/api/methods/parameters";


const listMethods = [
	ItemsService,
	OrdersService,
	CityLocateService,
	CityService,
	OrdersBasketService,
	OrdersCheckService,
	CartCheckService,
	ParametersService
];


@NgModule({
	imports: [HttpClientModule],
	declarations: [],
	providers: [
		ApiRequest,
		ApiService,
		ApiUtilsService,
		...listMethods
	],
	exports: []
})

export class ApiModule {
}


