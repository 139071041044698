import {Injectable} from '@angular/core';
import {get} from 'lodash-es';
import {environment} from 'environments/environment';

/**
 *
 * Console
 *  0.- Level.OFF
 *  1.- Level.ERROR
 *  2.- Level.WARN
 *  3.- Level.INFO
 *  4.- Level.DEBUG
 *  5.- Level.LOG
 */

@Injectable()
export class Config {
    public setting: any = environment;

    /**
     * Возвращает параметры конфига
     * @param key string
     * @returns {object}
     */
    public get (key: string) {

        if (key === '') {
            return '';
        }

        if (get(this.setting, key)) {
            return get(this.setting, key);
        }

        return '';
    }

    /**
     * Возвращает все параметры конфига
     *
     * @returns {any}
     */
    public getAll () {
        return this.setting;
    }
}
