/* Service */
import {NgModule} from '@angular/core';
import {ApiService} from './service/api';
import {ApiRequest} from './service/api/request';


/*Module*/
import {HttpClientModule} from '@angular/common/http';
import {ApiModule} from './service/api/module';
import {Config} from '@core/service/config';
import {Log} from '@core/service/logger';
import {CityService} from '@core/service/city';
import {Cookie} from '@core/service/cookie';
import {Platform} from '@core/service/platform';
import {Refresh} from '@core/service/refresh';
import {MobileService} from '@core/service/mobile';
import {Url} from '@core/service/url';
import {RedirectService} from '@core/service/redirect';
import {HttpStatus} from '@core/service/http-status';
import {LsService} from '@core/service/localstorage';
import {BasketService} from '@core/service/basket';
import {LazyScript} from '@core/service/lazy-script';
import {YandexMapsService} from '@core/service/yandex-maps';
import {RefererService} from '@core/service/referer';
import {Toast} from '@core/service/toast';
import {ToastrModule} from 'ngx-toastr';
import {TokenService} from '@core/service/token';
import {ParametersService} from "@core/service/parameters";


@NgModule({
	imports: [ToastrModule.forRoot(), HttpClientModule, ApiModule],
	declarations: [],
	providers: [
		ApiService,
		ApiRequest,
		Config,
		Log,
		CityService,
		Cookie,
		Platform,
		Refresh,
		MobileService,
		Url,
		RedirectService,
		HttpStatus,
		LsService,
		BasketService,
		LazyScript,
		YandexMapsService,
		RefererService,
		Toast,
		TokenService,
		ParametersService
	],
	exports: []
})

export class CoreModule {
}
