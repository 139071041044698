import { Injectable } from '@angular/core';
import { Refresh } from '@core/service/refresh';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@core/service/city/storage';
import { City } from './interface';
import { LsService } from '../localstorage';

@Injectable()
export class CityService extends Storage {
	public data: City;
	private cityNull: City = {
		id: 0,
		name: '',
		code: '',
		timezone: 0,
		latitude: 0,
		longitude: 0,
		region_id: 0,
		region_name: '',
		delivery_price: 0,
		free_delivery_sum: 0,
		sort: 0,
		is_delivery: false,
		is_payment: false,
	};

	public city$: BehaviorSubject<City> = new BehaviorSubject(this.cityNull);

	constructor(protected _localStorage: LsService, private _refresh: Refresh) {
		super(_localStorage);
	}

	/**
	 * Возращает текущий город из хранилище
	 *
	 */
	getCity(): number {
		return +this.city;
	}

	/**
	 * Запись города в хранилище
	 *
	 */
	setCity(obj: any, _refresh: boolean = true): void {
		if (obj.id) {
			this.city = obj.id.toString();
			this.data = obj;
			this.city$.next(obj);
		}

		if (_refresh) {
			this._refresh.routerOutletRefresh();
		}
	}
}
