import {Inject, Injectable} from '@angular/core';
import {Cookie} from '@core/service/cookie';
import {Storage} from '@core/service/referer/storage';
import {Platform} from '@core/service/platform';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class RefererService extends Storage {

    constructor(@Inject('ORIGIN_HEADERS') private _originHeaders: any,
                @Inject(DOCUMENT) private _document: Document,
                private _platform: Platform,
                protected _cookie: Cookie) {
        super(_cookie);
    }

    record() {
        if (this._platform.server) {
            if (this._originHeaders['referer']) {
                this.set(this._originHeaders['referer']);
            }
        }
    }

    public get() {
        let val = '';
        if (this.storage) {
            val = this.storage;
        } else {
            val = this._document.referrer;
        }
        return val;
    }

    private set(value: string) {
        this.storage = value;
    }

    public delete() {
        this.storage = '';
    }
}
