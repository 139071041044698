import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '@core/service/api';
import { SelectFind } from '@shared/module/input/select-find/select-find';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import { CityService } from '@core/service/city';
import { SelectFindService } from '@shared/module/input/select-find/select-find.service';
import { LoadingBigService } from '@shared/module/loading/loading-big/loading-big.service';

@Component({
	selector: 'app-city',
	templateUrl: './city.component.html',
	styleUrls: ['./city.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class CityComponent implements OnInit {
	public form: FormGroup;
	public options: SelectFind[] = [];
	public list: any[] = [];
	public loading: boolean = true;
	public isOpen: boolean = undefined;
	@ViewChildren('select_find') elemInput: ElementRef;

	constructor(
		private _api: ApiService,
		private _city: CityService,
		private _selectFindService: SelectFindService,
		private _loadingBigService: LoadingBigService,
		private _cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.form = new FormGroup({
			id: new FormControl(),
		});

		this._api.city.getAll().then((res) => {
			this.list = res.list;
			forEach(this.list, (v: any) => {
				this.options.push({
					id: v.id,
					name: v.name,
				});
			});
			this.loading = false;

			this._cdr.markForCheck();

			if (!this._city.data) {
				setTimeout(() => {
					this._selectFindService.open.next(true);
					this.isOpen = true;
				}, 100);
			} else {
				this.form.get('id').setValue(this._city.data.id);
				this._loadingBigService.status.next(false);
			}
		});

		this.form.get('id').valueChanges.subscribe((res) => {
			const tmp = find(this.list, { id: res });
			this._city.setCity(tmp);
			this._loadingBigService.status.next(true);
			this.isOpen = undefined;
		});
	}
}
